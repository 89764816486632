import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_SETTING_LIST, STORE_SETTING, UPDATE_SETTING_LIST } from "@/store/modules/setting.module";

export default {
  computed: {
    ...mapGetters(['setting', 'settings', 'settingError'])
  },
  methods: {
    getSettings(actions = {
      begin: ()=>{ this.showBusy('settingBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('settingBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_SETTING_LIST),
        actions
      );
    },
    updateSettings(formData = {}, actions = {
      begin: ()=>{ this.showBusy('settingBusy'); },
      after: (response, state)=>{ this.hideBusy('settingBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_SETTING_LIST, formData),
      actions
      );
    },
    storeSetting(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(STORE_SETTING, formData),
      actions
      );
    },
  }
}
